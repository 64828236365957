import { Grid, Stack } from '@mui/material';
import { FormikProps } from 'common/contracts';
import { fontWeightOptions } from 'utils/selectOptionsUtils';

import AcCard from 'components/AcCard/AcCard';
import AcInput from 'components/AcInput/AcInput';
import AcSelect from 'components/AcSelect/AcSelect';
import AcSolidInput from 'components/AcSolidInput/AcSolidInput';

import { OfferDesignTypes } from '../types';

const OfferUiTitle: React.FC<
  FormikProps & { shouldDisplaySubTitle: boolean }
> = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  shouldDisplaySubTitle
}) => {
  return (
    <>
      {values.offerUiType !== OfferDesignTypes.Bundle && (
        <Stack gap={3}>
          <AcCard
            title="Title"
            stackContainer={false}
            padded={false}
            className="offersUIFormTextCard"
          >
            <Grid container columnSpacing={{ xs: 1 }}>
              <Grid item xs={12} className="offersUIFormTextCard-offerContent">
                <AcInput
                  name="specialOffer.title"
                  value={values?.specialOffer?.title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>

              <Grid
                item
                xs={4}
                sx={{
                  paddingTop: '12px'
                }}
              >
                <AcSolidInput
                  header="Color"
                  name="specialOffer.fontColor"
                  defaultValue={values.specialOffer?.fontColor.colorOne}
                  onChange={handleChange}
                  setValue={(fieldName: string, color: string) => {
                    setFieldValue(fieldName, {
                      colorOne: color
                    });
                  }}
                  onBlur={handleBlur}
                  error={
                    touched.specialOffer?.fontColor?.colorOne &&
                    Boolean(errors.specialOffer?.fontColor?.colorOne)
                  }
                  helperText={
                    touched.specialOffer?.fontColor?.colorOne
                      ? errors.specialOffer?.fontColor?.colorOne?.toString()
                      : ''
                  }
                  imgWidth={'32px'}
                  imgHeight={'32px'}
                  roundedBorders={false}
                />
              </Grid>

              <Grid
                item
                xs={4}
                sx={{
                  paddingTop: '12px'
                }}
              >
                <AcInput
                  header="Size"
                  name="specialOffer.fontSize"
                  value={values.specialOffer?.fontSize}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="number"
                  slotProps={{
                    htmlInput: {
                      max: 20,
                      min: 8
                    }
                  }}
                  error={
                    values.specialOffer?.fontSize < 8 ||
                    values.specialOffer?.fontSize > 20
                  }
                  helperText={
                    values.specialOffer?.fontSize < 8
                      ? "Size can't be lower than 8"
                      : values.specialOffer?.fontSize > 20
                        ? "Size can't be greater than 20"
                        : ''
                  }
                />
              </Grid>

              <Grid
                item
                xs={4}
                sx={{
                  paddingTop: '12px'
                }}
              >
                <AcSelect
                  header="Weight"
                  name="specialOffer.fontWeight"
                  defaultValue="normal"
                  value={values.specialOffer?.fontWeight}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.specialOffer?.fontWeight &&
                    Boolean(errors.specialOffer?.fontWeight)
                  }
                  items={fontWeightOptions}
                />
              </Grid>
            </Grid>
          </AcCard>
          {shouldDisplaySubTitle && (
            <AcCard
              title="Sub Title"
              stackContainer={false}
              padded={false}
              className="offersUIFormTextCard"
            >
              <Grid container columnSpacing={{ xs: 1 }}>
                <Grid
                  item
                  xs={12}
                  className="offersUIFormTextCard-offerContent"
                >
                  <AcInput
                    name="specialOffer.subTitle.text"
                    value={values?.specialOffer?.subTitle?.text}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>

                <Grid
                  item
                  xs={4}
                  sx={{
                    paddingTop: '12px'
                  }}
                >
                  <AcSolidInput
                    header="Color"
                    name="specialOffer.subTitle.fontColor"
                    defaultValue={
                      values.specialOffer?.subTitle?.fontColor.colorOne
                    }
                    onChange={handleChange}
                    setValue={(fieldName: string, color: string) => {
                      setFieldValue(fieldName, {
                        colorOne: color
                      });
                    }}
                    onBlur={handleBlur}
                    error={
                      touched.specialOffer?.subTitle?.fontColor?.colorOne &&
                      Boolean(
                        errors.specialOffer?.subTitle?.fontColor?.colorOne
                      )
                    }
                    helperText={
                      touched.specialOffer?.subTitle?.fontColor?.colorOne
                        ? errors.specialOffer?.subTitle?.fontColor?.colorOne?.toString()
                        : ''
                    }
                    imgWidth={'32px'}
                    imgHeight={'32px'}
                    roundedBorders={false}
                  />
                </Grid>

                <Grid
                  item
                  xs={4}
                  sx={{
                    paddingTop: '12px'
                  }}
                >
                  <AcInput
                    header="Size"
                    name="specialOffer.subTitle.fontSize"
                    slotProps={{
                      htmlInput: {
                        max: 16,
                        min: 8
                      }
                    }}
                    value={values.specialOffer?.subTitle?.fontSize}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="number"
                    error={
                      values.specialOffer?.subTitle?.fontSize < 8 ||
                      values.specialOffer?.subTitle?.fontSize > 16
                    }
                    helperText={
                      values.specialOffer?.subTitle?.fontSize < 8
                        ? "Size can't be lower than 8"
                        : values.specialOffer?.subTitle?.fontSize > 16
                          ? "Size can't be greater than 16"
                          : ''
                    }
                  />
                </Grid>

                <Grid
                  item
                  xs={4}
                  sx={{
                    paddingTop: '12px'
                  }}
                >
                  <AcSelect
                    header="Weight"
                    name="specialOffer.subTitle.fontWeight"
                    defaultValue="normal"
                    value={values.specialOffer?.subTitle?.fontWeight}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.specialOffer?.subTitle?.fontWeight &&
                      Boolean(errors.specialOffer?.subTitle?.fontWeight)
                    }
                    items={fontWeightOptions}
                  />
                </Grid>
              </Grid>
            </AcCard>
          )}
        </Stack>
      )}
    </>
  );
};

export default OfferUiTitle;
